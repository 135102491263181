import {useSiteMetadata} from "../hooks";
import Layout from "../components/Layout";
import React from "react";
import NotFoundPage from "../components/NotFoundPage";

const NotFoundTemplate = ({ location }) => {
  const { subtitle: siteSubtitle } = useSiteMetadata();

  return (
    <Layout title="404" hideBreadcrumbs description={siteSubtitle} location={location}>
      <NotFoundPage/>
    </Layout>
  );
};

export default NotFoundTemplate;

